<template>
  <div class="preview_page">
    <div _title="面包屑">
      <div class="preview_bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'indexPage' }"
            >素材库首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{
            type == 1 ? "BP演示版" : type == 2 ? "BP文档版" : ""
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>当前作品</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div _title="主体" class="clearfix">
      <div _title="左侧" class="preview_left">
        <div class="preview_left_block" id="recommend">
          <div _title="标题和预览图" class="preview_info">
            <div _title="第一行" class="preview_info_line preview_info_first">
              <h1 class="preview_info_title">
                <!-- {{  imageList[0]  }} -->
                <!-- {{ types }} -->
                <!-- {{ form.tag }} -->
                <!-- {{ form }} -->

                <span>{{ types }}</span> {{ form.name }}
                <p class="h1_p">
                  <em> <i class="iconfont iconai-eye"></i>{{ form.pv }} </em>
                  <em> <i class="iconfont iconaixin"></i>{{ form.laud }} </em>
                </p>
              </h1>
              <div
                class="preview_info_fav_not"
                v-show="form.collect == false"
                @click="collection()"
              >
                <div class="preview_info_fav_line">
                  <i class="el-icon-star-off"></i>
                  <span class="preview_info_fav_text">收藏</span>
                </div>
              </div>
              <div
                class="preview_info_fav"
                v-show="form.collect == true"
                @click="collection()"
              >
                <div class="preview_info_not">
                  <i class="el-icon-star-on"></i>
                  <span class="preview_info_fav_text">已收藏</span>
                </div>
              </div>
            </div>
            <ul class="preview_img_list">
              <li
                class="preview_img_list_item"
                v-for="(item, index) in imageList"
                :key="index"
              >
                <div class="m-center-cntr">
                  <div class="t-c">
                    <img
                      class="preview_img_list_item_cover"
                      :src="item"
                      alt=""
                    />
                  </div>
                </div>
              </li>
            </ul>
            <div class="wbolt-box">
              <h3 class="wb-title">下载信息</h3>
              <div>
                <div
                  v-show="
                    (this.$store.state.vip == 'false' && form.buy == false) ||
                    (this.$store.state.vip == '' && form.buy == false) ||
                    (this.$store.state.vip == 'false' && form.buy == 'false') ||
                    (this.$store.state.vip == '' && form.buy == 'false') ||
                    (this.$store.state.vip == false && form.buy == 'false') ||
                    (this.$store.state.vip == false && form.buy == false)
                  "
                >
                  <span class="preview_price_title">单 价：</span>
                  <span _title="现价">
                    <span class="preview__price">
                      ￥
                      <span _title="现价数值" class="preview_price_final_value">
                        {{ form.price }}
                      </span>
                    </span>
                  </span>
                  <span
                    _title="会员免费"
                    class="Memberfree"
                    v-show="this.$store.state.vip == 'true'"
                    >会员免费</span
                  >
                </div>
                <div>
                  <div
                    class="Downloadnow"
                    @click="buy()"
                    v-show="
                      (this.$store.state.vip == 'false' && form.buy == false) ||
                      (this.$store.state.vip == '' && form.buy == false) ||
                      (this.$store.state.vip == false && form.buy == false) ||
                      (this.$store.state.vip == '' && form.buy == 'false') ||
                      (this.$store.state.vip == 'false' &&
                        form.buy == 'false') ||
                      (this.$store.state.vip == false && form.buy == 'false')
                    "
                  >
                    <!-- <div class="retail_moban_btn_text">  -->
                    立即下载
                    <!-- </div> -->
                  </div>
                  <div
                    class="Downloadnow"
                    v-show="
                      (this.$store.state.vip == 'false' && form.buy == true) ||
                      (this.$store.state.vip == false && form.buy == true) ||
                      (this.$store.state.vip == 'false' &&
                        form.buy == 'true') ||
                      (this.$store.state.vip == false && form.buy == 'true')
                    "
                    @click="download()"
                  >
                    直接下载
                  </div>
                  <div
                    class="Downloadnow"
                    v-show="
                      this.$store.state.vip == 'true' ||
                      this.$store.state.vip == true
                    "
                    @click="download()"
                  >
                    会员免费下载
                  </div>
                  <p class="botto_p">
                    <span class="copyinfo_title">授权范围</span>
                    <span>可做商用</span>
                  </p>
                  <p class="botto_p">
                    <span class="copyinfo_title">授权对象</span>
                    <span class="copy_one">个人</span>
                    <span
                      class="copy_two"
                      v-show="this.$store.state.vip == 'true'"
                      >高校</span
                    >
                  </p>
                  <p class="botto_p">
                    <span class="copyinfo_title">版权所有</span>
                    <span>素材库</span>
                  </p>
                </div>
              </div>
              <!-- 时间页数详情 -->
              <ul class="preview_detail clearfix">
                <li class="preview_detail_item">编号: {{ form.code }}</li>
                <li class="preview_detail_item">作者: {{ form.author }}</li>
                <li class="preview_detail_item">页数: {{ form.page }}页</li>
                <li class="preview_detail_item">格式: {{ form.format }}</li>
                <li class="preview_detail_item" v-if="form.tag">
                  <!-- <div class="mix_back"> -->
                   
                  <span
                    class=""
                    v-for="(citem, cindex) in form.tag.split(',')"
                    :key="cindex"
                    :title="form.tag"
                  >
                    <span class="mix_back_span">
                     
                      {{ citem }}
                    </span>
                  </span>
                  <!-- </div> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 相关推荐 -->
        <div class="preview_left_block" style="padding: 10px">
          <div class="preview_left_like">
            <h6 class="p_left_title">相关推荐</h6>
            <!-- PPT -->
            <div v-show="type == 1">
              <article
                class="post"
                v-for="(item, index) in recomList.slice(0, 12)"
                :key="index"
              >
                <div class="inner" @click="jump(1, item)" style="width: 195px">
                  <div class="media-pic">
                    <!-- <i class="tag-vip"></i> -->
                    <img
                      :src="item.cover"
                      width="600"
                      height="400"
                      alt=""
                      class="wp-post-image"
                    />
                    <!-- <router-link :to="{name:'PPThome'}" class="post-cate">
                                                <em class="tag-213">
                                                    演示版
                                                </em>
                                            </router-link> -->
                  </div>
                  <div class="media-body">
                    <a class="title" :title="item.name">
                      {{ item.name }}
                    </a>
                  </div>
                  <div class="clearfix">
                    <!-- <p class="post-date fl">
                                                <span class="anticon">
                                                    <i class="el-icon-time"></i>
                                                </span>
                                                {{item.time}}
                                            </p> -->
                    <p class="post-meta fr">
                      <em>
                        <i class="iconfont iconai-eye"></i>{{ item.pv }}
                      </em>
                      <em>
                        <i class="iconfont iconaixin"></i>{{ item.laud }}
                      </em>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <!-- WORD -->
            <div v-show="type == 2" class="articles-list">
              <article
                class="post posts"
                v-for="(item, index) in recomList.slice(0, 6)"
                :key="index"
                style="width: 50%"
              >
                <div
                  class="wode_iner"
                  @click="jump(2, item)"
                  style="width: 96%"
                >
                  <img :src="item.cover" alt="" />
                  <div class="flex-ccj">
                    <a class="">{{ item.name }}</a>
                    <div class="flex-ca">
                      <!-- <span class="anticon">
                                                    <i class="el-icon-time"></i>
                                                </span>
                                                <span>{{item.time}}</span> -->
                      <div class="flex_div">
                        <em>
                          <i class="iconfont iconai-eye"></i>{{ item.pv }}
                        </em>
                        <em>
                          <i class="iconfont iconaixin"></i>{{ item.laud }}
                        </em>
                      </div>
                    </div>
                    <div class="mix_back" v-if="item.tag">
                      <span
                        class=""
                        v-for="(citem, cindex) in item.tag.split(',')"
                        :key="cindex"
                      >
                        <span class="mix_back_span">
                          {{ citem }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div _title="右侧" class="preview_right">
        <div class="preview_right_block">
          <div>
            <!-- 模板价格 -->
            <div>
              <h3 class="wb-title">下载信息</h3>
              <div v-show="form.buy == false">
                <span class="preview_price_title">单 价：</span>
                <span _title="现价">
                  <span class="preview__price">
                    ￥
                    <span _title="现价数值" class="preview_price_final_value">
                      {{ form.price }}
                    </span>
                  </span>
                </span>
                <span
                  _title="会员免费"
                  class="Memberfree"
                  v-show="
                    this.$store.state.vip == 'true' ||
                    this.$store.state.vip == true
                  "
                  >会员免费</span
                >
              </div>
              <div>
                <div
                  class="Downloadnow"
                  @click="buy()"
                  v-if="form.buy == false"
                >
                  <!-- <div class="retail_moban_btn_text">  -->
                  购买
                  <!-- </div> -->
                </div>
                <div class="Downloadnow" v-else @click="download()">
                  直接下载
                </div>

                <p
                  class="botto_p"
                 
                >
                  <span class="copyinfo_title">温馨提示：</span>
                  <span
                    >建议使用Microsoft
                    Office、WPS打开文件，以避免可能出现的排版错乱问题。</span
                  >
                </p>
                <p class="botto_p">
                  <span class="copyinfo_title">授权范围：</span>
                  <span>可做商用</span>
                </p>
                <p class="botto_p">
                  <span class="copyinfo_title">授权对象：</span>
                  <span class="copy_one">个人</span>
                  <span class="copy_two" v-show="this.$store.state.vip"
                    >高校</span
                  >
                </p>
                <p class="botto_p">
                  <span class="copyinfo_title">版权所有：</span>
                  <span>素材库</span>
                </p>
              </div>
            </div>
            <!-- 时间页数详情 -->
            <ul class="preview_detail clearfix">
              <li class="preview_detail_item">编号: {{ form.code }}</li>
              <li class="preview_detail_item">作者: {{ form.author }}</li>
              <li class="preview_detail_item">页数: {{ form.page }}页</li>
              <li class="preview_detail_item">格式: {{ form.format }}</li>
              <li class="preview_detail_item">
                动画类型:
                {{
                  form.animation == 0
                    ? "无"
                    : form.animation == 1
                    ? "路演"
                    : form.animation == 2
                    ? "网评"
                    : ""
                }}
              </li>
              <li class="preview_detail_item" v-if="form.tag">
                <!-- <div class="mix_back"> -->
                <span
                  class=""
                  v-for="(citem, cindex) in form.tag.split(',')"
                  :key="cindex"
                  :title="form.tag"
                >
                  <span class="mix_back_span">
                    <!-- {{item.tag.replace(/,/g,"+")}} -->
                    {{ citem }}
                  </span>
                </span>
                <!-- </div> -->
              </li>
            </ul>
          </div>
        </div>
        <!-- 相关片段 -->
        <div
          class="preview_like_block"
          id="torecommed"
          :class="
            Fixed ? 'preview_like_fixed' : Fixeds ? 'preview_like_bottom' : ''
          "
        >
          <div class="preview_like">
            <h6 class="preview_like_title">相关片段</h6>
            <div class="preview_designer_tab_content clearfix">
              <!-- PPT -->
              <div v-show="type == 1">
                <article
                  class="post"
                  v-for="(item, index) in partList.slice(0, 4)"
                  :key="index"
                >
                  <div
                    class="inner"
                    @click="jump(1, item)"
                    style="width: 195px"
                  >
                    <div class="media-pic">
                      <!-- <i class="tag-vip"></i> -->
                      <img
                        :src="item.image.split(',')[0]"
                        width="600"
                        height="400"
                        alt=""
                        class="wp-post-image"
                      />
                      <!-- <router-link :to="{name:'PPThome'}" class="post-cate">
                                                <em class="tag-213">
                                                    演示版
                                                </em>
                                            </router-link> -->
                    </div>
                    <div class="media-body">
                      <a href="" class="title" :title="item.name">
                        {{ item.name }}
                      </a>
                    </div>
                    <div class="clearfix">
                      <p class="post-date fl">
                        <span class="anticon">
                          <i class="el-icon-time"></i>
                        </span>
                        {{ item.time }}
                      </p>
                      <p class="post-meta fr">
                        <em>
                          <i class="iconfont iconai-eye"></i>{{ item.pv }}
                        </em>
                        <em>
                          <i class="iconfont iconaixin"></i>{{ item.laud }}
                        </em>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <!-- WORD -->
              <div v-show="type == 2" class="articles-list">
                <article
                  class="post posts"
                  v-for="(item, index) in partList.slice(0, 4)"
                  :key="index"
                >
                  <div class="wode_iner" @click="jump(2, item)">
                    <img :src="item.image" alt="" />
                    <div class="flex-ccj">
                      <a class="">{{ item.name }}</a>
                      <div class="flex-ca">
                        <span class="anticon">
                          <i class="el-icon-time"></i>
                        </span>
                        <span>{{ item.time }}</span>
                        <div class="flex_div">
                          <em>
                            <i class="iconfont iconai-eye"></i>{{ item.pv }}
                          </em>
                          <em>
                            <i class="iconfont iconaixin"></i>{{ item.laud }}
                          </em>
                        </div>
                      </div>
                      <div class="mix_back" v-if="item.tag">
                        <span
                          class=""
                          v-for="(citem, cindex) in item.tag.split(',')"
                          :key="cindex"
                        >
                          <span class="mix_back_span">
                            {{ citem }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <div class="pay_Popup">
      <el-dialog
        title="购买文档"
        :visible.sync="dialogVisible"
        width="780px"
        :before-close="handleClose"
        :show-close="false"
      >
        <i
          class="el-icon-close closei"
          @click="closei()"
          v-show="pay_num != 2"
        ></i>
        <div v-show="pay_num == 1 && showtime == false">
          <div class="muban_detail">
            <p class="name"><i class="line"></i>{{ buys.title }}</p>
            <p class="ordernum">交易订单：{{ buys.number }}</p>
            <p class="final_price_wrapper">
              实际支付：
              <span class="final_price">
                <span class="symbol">￥</span> {{ form.price }}
              </span>
            </p>
          </div>
          <div class="qrcode_wrapper clearfix">
            <!-- 微信 -->
            <div class="pay-wx fl">
              <div class="wx-ewm">
                <img :src="wxPayCode" alt="" />
              </div>
              <div class="wx-icon">
                <img src="./../../assets/wx-icon.png" alt="" />微信扫码支付
              </div>
            </div>
            <!-- 支付宝 -->
            <div class="pay-zfb fr" @click="paybuy()">
              <div class="zfb-ewm">
                <img src="./../../assets/pay-zfb.png" alt="" />
              </div>
              <div class="pay-zfb-btn">
                <img src="./../../assets/zfb-icon.png" alt="" />去支付宝支付
              </div>
            </div>
          </div>
        </div>
        <div class="Paysuccess" v-show="pay_num == 2">
          <div class="Pay_div">
            <i class="el-icon-check"></i>
          </div>
          <p class="Pay_p1">支付成功</p>
          <p class="Pay_p2">{{ time }}S后自动跳转至...</p>
          <p class="Pay_p3" @click="jumps()">立即跳转</p>
        </div>
        <div v-show="showtime == true">
          <div class="mask_bg"></div>
          <div class="loading_wrapper">
            <i></i>
          </div>
        </div>
      </el-dialog>
    </div>
    <BetterLogin :title="title" @childFn="parentFn"></BetterLogin>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ruleForm: {},
      rules: {},
      form: {
        tag: "",
      },
      wxPayCode: "",
      Fixed: 0,
      Fixeds: 0,
      dialogSmallP: false,
      dialogVisible: false,
      title: "",
      imageList: [],
      types: "",
      partList: [],
      buys: {},
      showtime: true,
      time: "",
      pay_num: 1,
      recomList: [],
      type: 0,
    };
  },
  methods: {
    // 判断是否为小程序跳转
    loginKto() {
      var SmallFss = window.__wxjs_environment === "miniprogram";
      if (SmallFss == true) {
        if (
          this.$route.query.openid != "undefined" &&
          this.$route.query.openid != undefined &&
          this.$route.query.openid != null &&
          this.$route.query.openid != "null" &&
          this.$route.query.openid != ""
        ) {
          this.$store.dispatch("openid", this.$route.query.openid);
          this.axios
            .loginkto({
              openid: this.$store.state.openid,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$store.dispatch("token", res.data.data.token);
                this.$store.dispatch("account", res.data.data.account);
                this.$store.dispatch("avatar", res.data.data.avatar);
                this.$store.dispatch("userName", res.data.data.name);
                this.$store.dispatch("vip", res.data.data.vip);
                this.$store.dispatch("vipName", res.data.data.vipName);
                this.$store.dispatch("vipDate", res.data.data.vipDate);
                this.getapp();
              }
            })
            .catch((err) => {});
        } else {
          this.getapp();
        }
      } else {
        return false;
      }
    },
    getapp() {
      this.code = this.$route.query.code;
      this.type = this.$route.query.type;
      this.axios
        .preview({
          params: {
            code: this.code,
            token: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.form = res.data.data.item; //名字
            this.types = res.data.data.type; //完整版


         

            this.partList = res.data.data.part;
            if (this.form.image.split(",").length != 0) {
              this.imageList = this.form.image.split(",");

            
            } else {
              this.imageList = [];
            }
          } else {
            this.form = {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //详情页推荐
      this.axios
        .recomm({
          params: {
            code: this.code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.recomList = res.data.data;
          } else {
            this.recomList = [];
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(123)
        });
    },
    // 购买
    buy() {
      if (
        this.$store.state.token == "" ||
        this.$store.state.token == undefined
      ) {
        this.title = true;
      } else {
        var SmallF = window.__wxjs_environment === "miniprogram";
        if (SmallF == true) {
          var openid = this.$store.state.openid;
          this.axios
            .ordertrade({
              code: this.form.code,
              token: this.$store.state.token,
            })
            .then((res) => {
          
              if (res.data.code == 0) {
                wx.miniProgram.navigateTo({
                  url:
                    "/pages/util/pay?number=" +
                    res.data.data.number +
                    "&openid=" +
                    openid,
                });
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {});
        } else {
          (this.dialogVisible = true),
            this.axios
              .ordertrade({
                code: this.form.code,
                token: this.$store.state.token,
              })
              .then((res) => {
              
                if (res.data.code == 0) {
                  this.buys = res.data.data;
                  this.axios
                    .orderpay({
                      token: this.$store.state.token,
                      number: this.buys.number,
                      type: "wechat",
                    })
                    .then((res) => {
                      if (res.data.code == 0) {
                        this.showtime = false;
                        this.wxPayCode = res.data.data.payCode;
                        this.timing = setInterval(() => {
                          setTimeout(() => {
                            this.pay_status();
                          }, 0);
                        }, 2000);
                      } else {
                      }
                    })
                    .catch((err) => {});
                } else {
                  this.$message.info(res.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    },
    // 跳转支付宝
    paybuy() {
      this.axios
        .orderpay({
          token: this.$store.state.token,
          number: this.buys.number,
          type: "ali",
        })
        .then((res) => {
          if (res.data.code == 0) {
            let routerData = this.$router.resolve({
              path: "/orderAlipay",
              query: { htmlData: res.data.data },
            });
            // 打开新页面
            window.open(routerData.href);
          }
        })
        .catch((err) => {});
    },
    pay_status() {
      this.axios
        .orderquery({
          params: {
            number: this.buys.number,
            token: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.success == true) {
            clearInterval(this.timing); //销毁定时器
            this.pay_num = 2;
            this.time = 6;
            this.timer();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.dialogVisible = false;
        location.reload();
      }
    },
    jump(val, item) {
      if (val == 1) {
        this.$router.push({
          name: "Detailspage",
          query: { type: 1, code: item.code },
        });
        document.documentElement.scrollTop = 0;
        // document.body.scrollTop = 0;
        location.reload();
      } else if (val == 2) {
        this.$router.push({
          name: "Detailspage",
          query: { type: 2, code: item.code },
        });
        document.documentElement.scrollTop = 0;
        // document.body.scrollTop = 0;
        location.reload();
      }
    },
    // 立即跳转
    jumps() {
      this.dialogVisible = false;
      location.reload();
    },
    //立即下载
    download() {
      this.axios
        .dl({
          token: this.$store.state.token,
          code: this.form.code,
        })
        .then((res) => {
          if (res.data.code == 0) {
            var SmallFs = window.__wxjs_environment === "miniprogram";
            if (SmallFs == true) {
              wx.miniProgram.navigateTo({
                url: "/pages/util/download?fileUrl=" + this.form.url,
              });
            } else {
              window.location.href = this.form.url;
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose(done) {
      console.log(done);
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      this.Fixed =
        scrollTop > this.floorOne + this.offsetHeight &&
        scrollTop < this.floorTwo + this.reHeight;
      this.Fixeds = scrollTop > this.floorTwo + this.reHeight;
      if (this.Fixeds == true) {
        let obj = document.getElementById("torecommed");
        obj.style.top = 20 + "px";
      }
    },
    getFloorDis() {
      setTimeout(() => {
        let floorOne = document.getElementById("torecommed").offsetTop;
        this.floorOne = floorOne;
        let floorTwo = document.getElementById("recommend").offsetTop;
        this.floorTwo = floorTwo;
      }, 300);
    },
    //收藏
    collection() {
      if (
        this.$store.state.token == "" ||
        this.$store.state.token == undefined
      ) {
        this.title = true;
      } else {
        this.axios
          .collectionlike({
            token: this.$store.state.token,
            code: this.form.code,
            state: this.form.collect == false ? "t" : "f",
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.getapp();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // subxit(){

    // },
    // 关闭弹窗
    closei() {
      this.dialogVisible = false;
      this.dialogSmallP = false;
    },
    parentFn(payload) {
      this.title = payload;
    },
  },
  mounted() {
    this.loginKto();
    var query = this.$route.query;
    if (
      query.out_trade_no == undefined ||
      query.out_trade_no == "undefined" ||
      query.out_trade_no == "" ||
      query.out_trade_no == null
    ) {
      this.getapp();
    } else {
      this.axios
        .orderreturn({
          token: this.$store.state.token,
          number: query.out_trade_no,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$router.push({
              name: "Detailspage",
              query: { type: res.data.data.type, code: res.data.data.code },
            });
          } else {
            return false;
          }
        })
        .catch((err) => {});
    }
    this.getFloorDis();
    this.$nextTick(function () {
      setTimeout(() => {
        let head = document.getElementById("torecommed");
        this.offsetHeight = head.offsetHeight;
        let heads = document.getElementById("recommend");
        this.reHeight = heads.offsetHeight;
        window.addEventListener("scroll", this.handleScroll);
      }, 500);
    });
  },
};
</script>
<style scoped>
@import "../../styles/Page/Detailspage.css";
</style>
<style>
.preview_bread .el-breadcrumb {
  line-height: 51px;
}
.preview_bread .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #666;
}
.preview_bread .el-breadcrumb__inner.is-link {
  color: #666;
}
.pay_Popup .el-dialog {
  height: 500px;
}
.pay_Popup .el-dialog__header {
  padding: 0 0 0 20px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #3e3e3f;
}
.pay_Popup .el-dialog__title {
  font-size: 14px;
  color: #3e3e3f;
}
.pay_Popup .el-dialog__body {
  padding: 0;
}
.SmalP_popup .el-dialog__header {
  padding: 0 0 0 0px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  color: #3e3e3f;
  border-bottom: 1px solid #eaecee;
}
.SmalP_popup .el-dialog__title {
  font-size: 14px;
  color: #3e3e3f;
}
.SmalP_popup .el-dialog--center .el-dialog__body {
  padding: 25px 25px 15px;
}
</style>